import React,{Component} from 'react'
import { API, Auth, graphqlOperation,Storage,PhotoPicker, Logger } from 'aws-amplify'
// import {AmplifyS3ImagePicker} from 'aws-amplify'
import { createPost } from '../graphql/mutations'
import { withAuthenticator,AmplifyS3ImagePicker} from '@aws-amplify/ui-react';
import awsExports from "../aws-exports"

class CreatePost extends Component{
    state= {
        postOwnerId: "",
        postOwnerUsername: "",
        postTitle: "",
        postBody: "",
        image:null,
        url:null,
        errorMessage:"null",
        isDisabled:true,
    }
    componentDidMount = async() =>{
        await Auth.currentUserInfo()
             .then(user => {
                 this.setState({
                     postOwnerId:user.attributes.sub,
                     postOwnerUsername:user.username
                 })
                //  console.log("Curr: User: ",user.username)
                //  console.log("Attr:Sub: User: ",user.attributes.sub)
             })
    }
    handleChangePost = event => this.setState({
        [event.target.name] : event.target.value
        
    })
    handleAddPost = async event => {
        console.log("Entire State:",this.state)
        event.preventDefault()
        
        // const visibility ="public"
        // const filename=`/${visibility}/${this.state.postOwnerUsername}/${Date.now()}`

        // const uploadedFile1 = await Storage.put(filename,this.state.media.file,{
        //     contentType: this.state.media.type
        // })
        // const file = event.target.files[0];
        // console.log(file)
        
        // const uploadedFile = Storage.put(file.name,file,{
        //     contentType: 'image/png'
        // })
        
        // console.log('uploadedFile: ',uploadedFile)
        // const file = {
        //     key: uploadedFile.key,
        //     bucket: 'blogappmedia983242133222605-dev',
        //     region:'us-east-1'
        // }
        const input = {
            postOwnerId: this.state.postOwnerId,
            postOwnerUsername: this.state.postOwnerUsername,
            postTitle: this.state.postTitle,
            postBody: this.state.postBody,
            createdAt: new Date().toISOString(),
            image:this.state.image,
            key:this.state.key,
            region:this.state.region,
            bucket:this.state.bucket,
        }
        console.log('state image ',input.image)
        if (!input.key||!input.postTitle ||!input.postBody ){this.state.isDisabled=false}
        await API.graphql(graphqlOperation(createPost,{input}))
        this.setState({postTitle:"",postBody:"",image:null,url:null,errorMessage:"",isDisabled:true,key:null,region:null,bucket:null})
    }

    onChange(e){
        console.log('hellooo :')
        const file = e.target.files[0];
        console.log('length :',file.name)
        console.log(file)
        Storage.put(file.name,file,{
            contentType: 'image/png'
        }).then((result)=>{
            
            this.setState({url:URL.createObjectURL(file)})
            console.log('my result: ',URL.createObjectURL(file))
            const media =  {
                    bucket: awsExports.aws_user_files_s3_bucket,
                    region:awsExports.aws_user_files_s3_bucket_region,
                    key:'public/'+file.name
                }
            this.setState({image:media})
            this.setState({key:'public/'+file.name})
            this.setState({region:awsExports.aws_user_files_s3_bucket_region})
            this.setState({bucket:awsExports.aws_user_files_s3_bucket})
            this.setState({isDisabled:false})
             console.log(media);
            

        })
        .catch(err=>console.log(err))
    }


    s3Upload = async event => {

    }
    s3Upload1 = (file) => {
        const filename = `${Date.now()}-${file.name}`;
      
        const stored = Storage.put(filename, file, {
          contentType: file.type,
        });
      
        return stored.key;
    }
    // s3Upload1 = (e) => {
    //     const file = e.target.files[0];
    //     try {
    //         const stored = await Storage.put(file.name, file, {
    //         contentType: "image/png", // contentType is optional
    //       });
    //     } catch (error) {
    //       console.log("Error uploading file: ", error);
    //     }
    //     return stored.key;
    // }
    // uploadImage = () => {
    //     SetS3Config("my-test-bucket-amplify", "public");
    //     Storage.put(`userimages/${this.upload.files[0].name}`,
    //                 this.upload.files[0],
    //                 { contentType: this.upload.files[0].type })
    //       .then(result => {
    //         this.upload = null;
    //         this.setState({ response: "Success uploading file!" });
    //       })
    //       .catch(err => {
    //         this.setState({ response: `Cannot uploading file: ${err}` });
    //       });
    // };
    
    // onChange(e){
    //     const file = e.target.files[0];
    //     console.log(file)
    //     Storage.put(file.name,file,{
    //         contentType: 'image/png'
    //     }).then((result)=>{
    //         console.log('my resykt: ',result)
    //     })
    // }

    render(){
        return(
            <form className="add-post"
              onSubmit={this.handleAddPost}>
                <input style={{fort: '19px'}}
                  type="text" placeholder="Post Title"
                  name="postTitle"
                  required
                  value={this.state.postTitle}
                  onChange={this.handleChangePost}
                  />
                <textarea 
                  type="text"
                  name="postBody"
                  rows="3"
                  cols="40"
                  required
                  placeholder="New Blog Post"
                  value={this.state.postBody}
                  onChange={this.handleChangePost}
                 />
                {!this.state.image &&(
                 <input required type="file" name="myImage" accept="image/*" key={this.state.media || '' }  onChange={(evt)=>this.onChange(evt)} />
                )
                }
                {this.state.image &&(
                 <img src ={this.state.url}  /> 
                )
                }
                <input type="submit"
                    className="btn"
                    disabled = {!this.state.postTitle||!this.state.postBody||!this.state.key}
                    style={{fontSize:'19px'}}/>
                    
                    
            </form>
        )
    }
}

export default CreatePost