/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://rdr5jr3jgfeajdlapagpuybjcm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:7d345e14-1411-4247-8dc4-da56ae68459c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_X4SioXJnu",
    "aws_user_pools_web_client_id": "6oj34qk9lelgr6dgmj5mvet4m8",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "blogapp-20220109195853-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2nq40z64jp41z.cloudfront.net",
    "aws_user_files_s3_bucket": "blogappmedia983242133222605-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
