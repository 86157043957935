
import React from 'react';
import './App.css';
import CreatePost from './components/CreatePost';
import DisplayPosts from "./components/DisplayPosts";

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

function App() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <div className="App">
          <div>
            <p>
            Hey {user.username.charAt(0).toUpperCase()+ user.username.slice(1)}, Welcome Back !!!!
            <button onClick={signOut}>Sign out</button>
          </p>
          <br/>
          <CreatePost />
          <DisplayPosts />
          </div>
        </div>
      )}
    </Authenticator>
  );
}
 
export default App;
