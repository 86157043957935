import React, { Component } from 'react'
import { API, Auth, graphqlOperation,Storage,PhotoPicker, Logger } from 'aws-amplify'

class ShowS3Img extends Component {
    state = {
        url:""
    }
    componentWillMount = async () => {
        console.log('this.props.key: ',JSON.stringify(this.props.data))
        const key = this.props.data.replace('public/','')
        console.log('key: ',key)
        const signedURL = await Storage.get(key.toString())
        console.log('signedURL: ',signedURL)
        this.setState({url:signedURL})
        // this.setState({url:"https://blogappmedia983242133222605-dev.s3.us-east-1.amazonaws.com/public/sg_img.jpg?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEAMaDmFwLXNvdXRoZWFzdC0xIkYwRAIgITJXSjgYiFAn3uOiD%2BzRkv51yLQsLeZDDB8IFDMsH88CIAisFfMgohaEC7mh5vIP%2Bw383TecnnC9EyF8eIuUrz47KpcDCBwQABoMNjY1Nzc4NDYyODc4IgxqVdyG5SwtOHE%2FClYq9AJVwu981%2BJVXqT8rCyxUibJaGkP6CayKVaI5ZHDPL4pR%2B6Rq7QmgQmn%2FZivbcHEtCJNu%2BejyaxBqes345vqs05nMj1gm5y2gWNtej5euRHc%2FZlpYkO7VXVu7HN637os%2FUwM6BDq53cCK8hwCqSsRGLmJnX9km5VyLB2fSXfInh%2BYn7iR0job%2FRYLotEGPYKh4ZTg7APsyFYly9GMtu78f0t8FwOA9MxRFmJvoG%2Fq38Wu3CpvZxHJKuespWiqH0CDTJRv7PEoLg05%2BcstBTptpzvIcFnU2w5vtUAlFde1z4Jb8BYhqQexb4ff0aKuHO3HjtwaNbqt2vuXNAQVCl0nkbe1YNsiK3DfyFtm02lNTIuX4gguXvmDop%2B7khkOorP%2BW7gu%2FTFdxbgoJdlRO1XB%2Fh4enBxunaAeyIJSEWoKW1y7h5p1tPMjps4Y%2FFI8MUwWgekWqEW3uO6yIX1%2BZ4DQV%2B%2BFp5JumZyK01nPbFWzhEDMTQf3g0wloTyjgY6tAJfTQAChq5onyhoCN3s6l%2F3tYz7zA%2FKBWsFqPQqKFu7GH8149ZutUtviM4E1gPcH%2FTZxayitDCGa4Us%2BEg6HAWKe8WPaGbYqws99MLC7HTzv16FYNY30KkAPAfIeD%2BRkHx9nzSm%2F91oohjpVLnhH0FqH2PgExWjAFlQGrPQSogwHG17NgxP5qRCb58l8MuHxV0A2d0WaPdMhZY2kw0FEQkgi2w2roiAQq%2BYWreJgVZPoosjeA98p0g29UATC1KqrshN9ffV%2FbNYgfAvzbQmWulXpTBBqBx%2FXP9Ln15W2A9cwx7%2B72gnvRqvvHh08ZvAnY4ENvhBNkN78vBFvdu%2FaARaHU5tVMjtn9rpnNbj5njIL73iaaZ%2BGtG9FDWD%2BS%2BoapbVFCflCV%2B4OQ08OapmD13JdOdUAA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20220110T185939Z&X-Amz-SignedHeaders=host&X-Amz-Expires=300&X-Amz-Credential=ASIAZWA35ECPNNQT7WRA%2F20220110%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=c4c737df162a5e18510f6668ab7b8dc11a6c1b4e3915041800d549b65e93c721"})
        
         
    }

    
    render() {
        return (
            <>
            <img className="image" src={this.state.url}   />  
            </>)
        }
}

export default ShowS3Img;